var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.showId === 'fertilizer' ||
    _vm.showId === 'soil' ||
    _vm.showId === 'water' ||
    _vm.showId === 'feed' ||
    _vm.showId === 'machine' ||
    _vm.showId === 'gate' ||
    _vm.showId === 'insect' ||
    _vm.showId === 'heat' ||
    _vm.showId === 'control'
  )?_c('div',{staticClass:"equipment"},[_c('div',{staticClass:"eq-title"},[_vm._v("设备状态")]),_c('div',{staticClass:"eq-lists"},_vm._l((_vm.colDataList),function(item){return _c('div',{key:item.id,staticClass:"eq-items"},[_c('p',{staticClass:"name one-txt-cut"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"val"},[_vm._v(_vm._s(item.last_data)+_vm._s(item.data_unit))]),(item.type === 1)?_c('el-switch',{staticClass:"my-switch",class:item.last_data ? 'active' : '',on:{"change":function($event){return _vm.changeSwitch($event, item.id)}},model:{value:(item.last_data),callback:function ($$v) {_vm.$set(item, "last_data", $$v)},expression:"item.last_data"}}):_vm._e()],1)}),0)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }